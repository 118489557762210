<div class="main">
    <app-header></app-header>
<div class="lottery-content">
    <div class="content-main mt-5 effect-left">
        <h1>{{operator_name}} <span>Piyango</span> ile kazanmaya ve eğlenmeye <span>hazır mısın?</span></h1>
        <p class="mt-4 mb-4">{{operator_name}} Piyango ile sitemiz üzerinde gerçekleştirmiş olduğunuz yatırımlarınız üzerinden puan talep edebilirsiniz ve almış olduğunuz puanlarla birlikte bilet satın alabilirsiniz. Şeffaf ve güvenli olması açısından Milli Piyango İdaresi'nin gerçekleştirmiş olduğu çekilişin son 4 rakamı baz alınmaktadır.</p>
        <div class="login">
            <input [(ngModel)]="username" type="text" placeholder="Kullanıcı Adınız">
            <input (click)="login()" class="login-button" type="submit" value="Giriş Yap">
        </div>
    </div>
    <img class="lottery-girl effect-right" src="assets/img/lottery-girl-christmast.png"/>
</div>
<div class="lottery-footer mt-3">
    <div class="statistics">
        <div class="current-tour-tickets">
            <h2>{{current_week_ticket_count}}+</h2>
            <span>Bu hafta <br>satılan toplam bilet</span>
        </div>
        <div class="total-attendees">
            <h2>{{participants_count}}</h2>
            <span>Bu hafta <br>toplam katılımcı</span>
        </div>
        <div class="total-tickets">
            <h2>{{all_week_ticket_count}}+</h2>
            <span>Tüm zaman <br>toplam satılan bilet</span>
        </div>
    </div>
    
</div>
<app-footer></app-footer>
</div>
