import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSafeComponent } from './pages/account-safe/account-safe.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'account-safe', component: AccountSafeComponent},
  {path: 'login',component:LoginComponent},
  {path: 'settings',component:SettingsComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
