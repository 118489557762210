import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AccountSafeComponent } from './pages/account-safe/account-safe.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LoginComponent } from './pages/login/login.component';
import { HeaderPanelComponent } from './components/header-panel/header-panel.component';
import { ProfileContentComponent } from './pages/profile-content/profile-content.component';
import { BalanceService } from './services/balance.service';
import { ClipboardModule } from 'ngx-clipboard';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { SettingsComponent } from './pages/settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    AccountSafeComponent,
    ProfileComponent,
    LoginComponent,
    HeaderPanelComponent,
    ProfileContentComponent,
    TicketsComponent,
    SettingsComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    SimpleNotificationsModule.forRoot({position:["bottom","right"]}),
    ClipboardModule
  ],
  providers: [
    BalanceService,
    HeaderPanelComponent,
    ProfileComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
