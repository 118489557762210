import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public notification:NotificationsService,private api:ApiService,private router:Router) { }
password
username
  ngOnInit(): void {
    this.username = localStorage.getItem("username");
  }

  login(){
    
    document.getElementsByClassName("secure-button")[0].setAttribute("disabled","disabled");
    document.getElementsByClassName("secure-button")[0].classList.add("btn-disabled")

    this.api.login_with_password(this.password).subscribe(data=>{
      document.getElementsByClassName("secure-button")[0].removeAttribute("disabled");
      document.getElementsByClassName("secure-button")[0].classList.remove("btn-disabled")

      if(data.error == false){
        localStorage.setItem("token",data.token)
        this.show_notification(1,"Giriş işlemi başarılı.")
        localStorage.setItem("userdata",JSON.stringify(data.user_data))
        this.router.navigate(["/profile"])
      }else{
        this.show_notification(2,data.msg)
      }
    })
  }

  go_back(){
    localStorage.clear();
    this.router.navigate(["/"]);
  }

  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

}
