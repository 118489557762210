
<div class="main">
    <app-header></app-header>
    <div id="piyango">
        <app-header-panel></app-header-panel>
        <app-profile-content *ngIf="MenuService.selectedMenu == 'profile'"></app-profile-content>
        <app-tickets *ngIf="MenuService.selectedMenu == 'tickets'"></app-tickets>
        <app-settings *ngIf="MenuService.selectedMenu == 'settings'"></app-settings>

    </div>
    
    <app-footer></app-footer>
</div>
