import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { ApiService } from 'src/app/services/api.service';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from '../../../environments/environment'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  operator_name = environment.operator_name;
  constructor(private router:Router,public MenuService:MenuService,public profile:ProfileComponent,private api:ApiService) { }
  type=0;
  menu_action_val = 0
  reload_val = 0;
  user = false;
  go_website = "";
  operator_image=""
  ngOnInit(): void {
    if(localStorage.getItem("userdata") != null){
      this.type = 1;
    }
    if(localStorage.getItem('userdata') != null){
      var data =JSON.parse(localStorage.getItem('userdata'));
      if(data["id"] != null){
        this.user = true;
      }
    }
    if(window.location.hash.indexOf("profile") == -1){
      this.api.get_operator_config().subscribe((data)=>{
        if(data.error == false){
          this.go_website = data.data["operator_website"]
          this.operator_image = data.data["operator_image"]
         
        }
      })
    }
  }

  reload_page(){
    window.location.reload();

  }

  menu(page){
    this.MenuService.selectedMenu = page;
    if(this.menu_action_val == 1){
      this.close_menu();
      if(page == "profile"){
        this.profile.ngOnInit()
      }
    }
  }

  logout(){
    localStorage.clear();
    this.router.navigate(["/"]);
  }

  open_menu(){
    this.menu_action_val = 1;
    document.getElementById("menuMobile").style.display = "flex"
      document.getElementById("menuMobile").classList.add("bounce-in-left")
      document.getElementById("piyango").style.filter = "blur(3px)"
      document.getElementById("piyango").style.pointerEvents = "none"
      document.getElementById("lotteryHeaderMobile").style.filter="blur(3px)"
      document.getElementById("lotteryHeaderMobile").style.pointerEvents="none"

      setTimeout(() => {
        document.getElementById("menuMobile").classList.remove("bounce-in-left")
      }, 1100);
  }

  close_menu(){
    this.menu_action_val = 0
    document.getElementById("menuMobile").classList.add("slide-out-left")
    document.getElementById("piyango").style.filter = "blur(0px)"
    document.getElementById("piyango").style.pointerEvents = "auto";
    document.getElementById("lotteryHeaderMobile").style.filter="blur(0px)"
    document.getElementById("lotteryHeaderMobile").style.pointerEvents="auto"

    setTimeout(() => {
      document.getElementById("menuMobile").style.display = "none"
      document.getElementById("menuMobile").classList.remove("slide-out-left")
    }, 500);
  }

}
