<div class="main-header">
    <div class="header-gradient"></div>
    <div id="headerPanel" class="container header-panel">
        <mat-spinner class="container" *ngIf="loading && web == true" diameter="75"></mat-spinner>

        <div *ngIf="!loading" class="info">
            <div class="header">
                <div class="title">{{operatorName}} Piyango <span>HAFTA #{{tour_no}}</span></div>
            </div>
            <div class="content">
                Toplam Ödül: {{prize}}₺
            </div>
        </div>
        <div *ngIf="!loading" class="date">
            <div class="title">
                {{lottery_date_day}} {{lottery_date_month}}  <span>{{lottery_date_time}}</span>
            </div>
            <div class="content mt-2">
                <div class="items">
                    <div class="number">
                        {{countdown_days}}
                    </div>
                    <div class="name">
                        Days
                    </div>
                </div>
                <div class="colon">
                    :
                </div>
                <div class="items">
                    <div class="number">
                        {{countdown_hours}}
                    </div>
                    <div class="name">
                        Hours
                    </div>
                </div>
                <div class="colon">
                    :
                </div>
                <div class="items">
                    <div class="number">
                        {{countdown_minutes}}
                    </div>
                    <div class="name">
                        Mins
                    </div>
                </div>
                <div class="colon">
                    :
                </div>
                <div class="items">
                    <div class="number">
                        {{countdown_seconds}}
                    </div>
                    <div class="name">
                        Secs
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading" class="point">
            <div class="point-box">
                <div class="point-info">
                    Puan: <span>{{balanceService.balance}}</span>
                </div>
                    <button (click)="point_request()" class="point-request-button">Puan Talep Et</button>
            </div>
        </div>
    </div>
</div>