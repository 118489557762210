import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(public notification:NotificationsService,public api:ApiService,public router:Router,private page:MenuService) { }
  current_password;
  password_again;
  password;
  operatorName = environment.operator_name;
  current_password_val=0;
  new_password_val = 0
  new_password_again_val = 0;
  ngOnInit(): void {
    var userdata = JSON.parse(localStorage.getItem('userdata'));
    if(userdata["mail"] == null){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['account-safe']);
        this.page.selectedMenu = "profile";

    }); 
    }
  }

  save(){
    if(this.password != null && this.password_again != null && this.current_password != null){
        if(this.password_again == this.password){
          this.api.change_password(this.current_password,this.password).subscribe((data)=>{
            if(data.error == false){
              this.show_notification(1,data.msg);
            }else{
              this.show_notification(2,data.msg) 
            }
          })
        }else{
          this.show_notification(2,"Girdiğiniz şifreler birbiri ile uyuşmamaktadır.")
        }
      }else{
        this.show_notification(2,"Boş alan bırakmayınız.")
      }

  }

  back(){
   this.page.selectedMenu = 'profile'
  }

  show_password(dom){
    if(dom == "current_password"){
      if(this.current_password_val == 0){
        this.current_password_val = 1;
        document.getElementById(dom).setAttribute("type","text");
      }else{
        this.current_password_val =0;
        document.getElementById(dom).setAttribute("type","password");
      }

    }else if(dom == "new_password"){
      if(this.new_password_val == 0){
        this.new_password_val = 1;
        document.getElementById(dom).setAttribute("type","text")
      }else{
        this.new_password_val = 0
        document.getElementById(dom).setAttribute("type","password")
      }
    }else if(dom == "new_password_again"){
      if(this.new_password_again_val == 0){
        this.new_password_again_val = 1;
        document.getElementById(dom).setAttribute("type","text")
      }else{
        this.new_password_again_val = 0
        document.getElementById(dom).setAttribute("type","password")
      }
    }
  }

  
  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

}
