import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AppComponent } from 'src/app/app.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  operator_name = environment.operator_name;
  username;
  id;
  current_week_ticket_count
  all_week_ticket_count
  participants_count
  constructor(public notification:NotificationsService,private api:ApiService,private router:Router,private activatedRoute:ActivatedRoute,public appComponent:AppComponent) {
  

   }

  ngOnInit(): void {
    this.appComponent.ngOnInit();
    this.api.get_statistic_data().subscribe(data=>{
      if(data.error==false){
        this.current_week_ticket_count = data.data["current_week_ticket_count"]
        this.all_week_ticket_count = data.data["all_week_ticket_count"]
        this.participants_count = data.data["participants_count"]
      }else{
        this.show_notification(2,data.msg);
      }
    })
  }

  login(){
    if(this.username != ""){
         
    document.getElementsByClassName("login-button")[0].setAttribute("disabled","disabled");
    document.getElementsByClassName("login-button")[0].classList.add("btn-disabled")
      this.api.login(this.username).subscribe(data=>{
        document.getElementsByClassName("login-button")[0].removeAttribute("disabled");
        document.getElementsByClassName("login-button")[0].classList.remove("btn-disabled")
        if(data.error == false){
          if(data.user == true && data.unauth == true && data.user_data["first_login"] == 0){
            localStorage.setItem("username",this.username);
            localStorage.setItem("userdata",JSON.stringify(data.user_data))
            localStorage.setItem("user","true")
            if(data.token != null){
              localStorage.setItem("token",data.token)
            }
            this.router.navigate(["account-safe"]);
          }else{
            if(data.user == true && data.unauth == false){
              localStorage.setItem("username",this.username);
              localStorage.setItem("token",data.token);
              this.router.navigate(['/login']);
            }else{
              if(data.user == false){
                localStorage.setItem("user","false");
              }else{
                localStorage.setItem("user","true");
              }
              localStorage.setItem("username",this.username);
              localStorage.setItem("userdata",JSON.stringify(data.user_data))
              localStorage.setItem("token",data.token)
              this.router.navigate(["profile"])
            }
           
          }
        }
      })
    }
  }

  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }


}
