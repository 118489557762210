import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';
import { BalanceService } from 'src/app/services/balance.service';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private api:ApiService,public notification:NotificationsService,public balanceService:BalanceService,public MenuService:MenuService) { }
  sliderTicket =0;
  affiliateURL = environment.operatorAffiliateUrl;
  operatorName = environment.operator_name
  user_id 
  full_ticket_cost
  half_ticket_cost
  quarter_ticket_cost
  prize
  commission
  tour_no
  username
  balance
  operator_detail_loading = false;
  user_detail_loading = false;
  full_ticket_shop_count = 1;
  half_ticket_shop_count = 1;
  quarter_ticket_shop_count =1;
  full_ticket_shop_cost
  half_ticket_shop_cost
  quarter_ticket_shop_cost
  top_winner_data;
  lottery_date_month
  lottery_date_day
  lottery_date_time

  countdown_check = 0;
  countdown_days = 0;
  countdown_minutes = 0;
  countdown_hours = 0;
  countdown_seconds = 0;
  data = [];
  ngOnInit(): void {

    this.api.operator_detail().subscribe(data=>{
      if(data.error == false){
        this.operator_detail_loading = true;
        this.full_ticket_cost = data.operator_data["operator_full_ticket_cost"]
        this.half_ticket_cost = data.operator_data["operator_half_ticket_cost"]
        this.quarter_ticket_cost = data.operator_data["operator_quarter_ticket_cost"]
        this.full_ticket_shop_cost = data.operator_data["operator_full_ticket_cost"]
        this.half_ticket_shop_cost = data.operator_data["operator_half_ticket_cost"]
        this.quarter_ticket_shop_cost = data.operator_data["operator_quarter_ticket_cost"]
        this.top_winner_data = data.top_winner_data;
        this.prize = data.operator_data["operator_prize"]
        this.commission = data.operator_data["operator_affiliate_commission"]
        this.tour_no = data.system_data
        this.lottery_date_month = data.lottery_date["month"]
        this.lottery_date_day = data.lottery_date["day"]
        this.lottery_date_time = data.lottery_date["time"]

        if(data.countdown["days"] != "false" && data.countdown["hours"] != "false" && data.countdown["minutes"] != "false" && data.countdown["seconds"] != "false"){
          this.countdown_check = 1;
          this.countdown_days = data.countdown["days"]
          this.countdown_hours = data.countdown["hours"]
          this.countdown_minutes = data.countdown["minutes"]
          this.countdown_seconds = data.countdown["seconds"]
        }

      }
    })

    this.api.user_detail().subscribe(data=>{
      if(data.error == false){
        if(data.user_data["id"] != null){
          this.user_id = data.user_data["id"];
          localStorage.setItem("userdata",JSON.stringify(data.user_data));
        }else{
          this.user_id = 0;
        }
        this.user_detail_loading = true;
        this.username = data.user_data["username"]
        if(data.user_data["balance"] != null){
          this.balanceService.balance = data.user_data["balance"];
        }else{
          this.balanceService.balance = 0;
        }
      }
    })
  }



}
