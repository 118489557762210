<div class="container mt-3">
    <mat-spinner  class="container mb-5" *ngIf="loading" diameter="75"></mat-spinner>
    <div *ngIf="!loading" class="buy-ticket mt-5">
        <div class="header">
            <div class="title">
                <svg width="48" height="53" viewBox="0 0 48 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.269531 0V54H47.7305V0H0.269531Z" fill="var(--main-svg-color)"/>
                <path d="M24 0H47.7305V54H24V0Z" fill="var(--main-svg-color-dark)"/>
                <path d="M35.0742 33.3281V43.4847L30.3281 48.2308L27.1641 45.0667L24 48.2308L20.8359 45.0667L17.6719 48.2308L12.9258 43.4847V33.3281H35.0742Z" fill="#F2F2FC"/>
                <path d="M24 33.3281H35.0742V43.4847L30.3281 48.2308L27.1641 45.0667L24 48.2308V33.3281Z" fill="#E2D9FB"/>
                <path d="M6.59766 6.43359H41.4023V28.582H6.59766V6.43359Z" fill="#F2F2FC"/>
                <path d="M24 6.43359H41.4023V28.582H24V6.43359Z" fill="#E2D9FB"/>
                <path d="M35.0742 9.59766H38.2383V12.7617H35.0742V9.59766Z" fill="#32393F"/>
                <path d="M9.76172 15.9258H12.9258V19.0898H9.76172V15.9258Z" fill="#474F54"/>
                <path d="M16.0898 9.59766H31.9102V25.418H16.0898V9.59766Z" fill="#474F54"/>
                <path d="M35.0742 15.9258H38.2383V19.0898H35.0742V15.9258Z" fill="#32393F"/>
                <path d="M9.76172 9.59766H12.9258V12.7617H9.76172V9.59766Z" fill="#474F54"/>
                <path d="M9.76172 22.2539H12.9258V25.418H9.76172V22.2539Z" fill="#474F54"/>
                <path d="M35.0742 22.2539H38.2383V25.418H35.0742V22.2539Z" fill="#32393F"/>
                <path d="M24 9.59766H31.9102V25.418H24V9.59766Z" fill="#32393F"/>
                <path d="M9.76172 31.7461H38.2383V34.9102H9.76172V31.7461Z" fill="#474F54"/>
                <path d="M24 31.7461H38.2383V34.9102H24V31.7461Z" fill="#32393F"/>
                </svg>
                <div class="ml-2">BILET<span>SATIN AL</span></div>
            </div>
            <div class="content">Güncel puanınız dahilinde&nbsp;<span>bilet</span>&nbsp;satın alabilirsiniz</div>
        </div>

        <div class="content mt-5">
            <span (click)="previous()"  style="display: none;width: 35px;;" class="left">
                <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3096 22.52L0.0776253 11.576L11.3096 0.631999H17.7416L6.46163 11.576L17.7416 22.52H11.3096Z" fill="white"/>
                </svg>
            </span>

            <div id="ticket_1" class="ticket">
                <div class="ticket-header">
                    <div class="icon">
                        <svg width="54" height="64" viewBox="0 0 54 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.5179 0.440674L42.8905 2.19981C42.3973 3.59806 41.3816 4.717 40.0324 5.3536C38.6758 5.9902 37.1597 6.06808 35.7487 5.56221C34.715 5.20132 33.7648 4.47769 33.0755 3.52018L29.7133 6.2804V55.9595V59.6736L33.4274 63.3878L54.0002 4.23094L43.5179 0.440674Z" fill="#AE2538"/>
                            <path d="M33.4272 44.6934L31.1244 46.5504L33.4272 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596C13.6307 55.9596 11.1424 58.4479 11.1424 61.5308V63.3878H0V48.4075L3.71413 46.5504L0 44.6934V0H11.1424V1.85707C11.1424 4.93967 13.6307 7.42826 16.7136 7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4272V44.6934Z" fill="#FF6C6C"/>
                            <path d="M33.4271 44.6934L31.1244 46.5504L33.4271 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596V7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4271V44.6934Z" fill="#E63950"/>
                            <path d="M0 44.6934H7.42826V48.4075H0V44.6934Z" fill="#FFD396"/>
                            <path d="M11.1424 44.6934H14.8565V48.4075H11.1424V44.6934Z" fill="#FFD396"/>
                            <path d="M18.5705 44.6934H22.2847V48.4075H18.5705V44.6934Z" fill="#FFB64C"/>
                            <path d="M25.999 44.6934H33.4273V48.4075H25.999V44.6934Z" fill="#FFB64C"/>
                            <path d="M26.4074 21.7028L19.1278 23.0026L16.7135 20.477L13.9279 17.543L12.8879 25.0825L6.31396 28.3881L12.8879 31.7309L13.9279 39.2705L16.7135 36.3365L19.1278 33.8109L26.4074 35.0735L22.9905 28.3881L26.4074 21.7028Z" fill="#FFD396"/>
                            <path d="M22.9904 28.3883L26.4074 35.0736L19.1279 33.8109L16.7136 36.3365V20.4771L19.1279 23.0027L26.4074 21.7028L22.9904 28.3883Z" fill="#FFB64C"/>
                            </svg>                                
                    </div>
                    <div class="header ml-2">
                        <div class="title">
                            Çeyrek <br> Bilet
                        </div>
                        <div class="info mt-1">
                            {{quarter_ticket_cost}} PUAN
                        </div>
                    </div>
                </div>
                <div class="ticket-content mt-5">
                    <div class="point">
                        {{quarter_ticket_shop_cost}} PUAN
                    </div>
                     <div class="count">
                         <button (click)="remove('quarter')" class="minus">-</button>
                         <span class="number">{{quarter_ticket_shop_count}}</span>
                         <button (click)="add('quarter')" class="plus">+</button>
                     </div>
                </div>
                <button (click)="buy_ticket('quarter')" class="buy-button mt-5">
                    Bilet Satın Al
                </button>
            </div>
            <div id="ticket_2" class="ticket">
                <div class="ticket-header">
                    <div class="icon">
                        <svg width="54" height="64" viewBox="0 0 54 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.5179 0.440674L42.8905 2.19981C42.3973 3.59806 41.3816 4.717 40.0324 5.3536C38.6758 5.9902 37.1597 6.06808 35.7487 5.56221C34.715 5.20132 33.7648 4.47769 33.0755 3.52018L29.7133 6.2804V55.9595V59.6736L33.4274 63.3878L54.0002 4.23094L43.5179 0.440674Z" fill="#AE2538"/>
                            <path d="M33.4272 44.6934L31.1244 46.5504L33.4272 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596C13.6307 55.9596 11.1424 58.4479 11.1424 61.5308V63.3878H0V48.4075L3.71413 46.5504L0 44.6934V0H11.1424V1.85707C11.1424 4.93967 13.6307 7.42826 16.7136 7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4272V44.6934Z" fill="#FF6C6C"/>
                            <path d="M33.4271 44.6934L31.1244 46.5504L33.4271 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596V7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4271V44.6934Z" fill="#E63950"/>
                            <path d="M0 44.6934H7.42826V48.4075H0V44.6934Z" fill="#FFD396"/>
                            <path d="M11.1424 44.6934H14.8565V48.4075H11.1424V44.6934Z" fill="#FFD396"/>
                            <path d="M18.5705 44.6934H22.2847V48.4075H18.5705V44.6934Z" fill="#FFB64C"/>
                            <path d="M25.999 44.6934H33.4273V48.4075H25.999V44.6934Z" fill="#FFB64C"/>
                            <path d="M26.4074 21.7028L19.1278 23.0026L16.7135 20.477L13.9279 17.543L12.8879 25.0825L6.31396 28.3881L12.8879 31.7309L13.9279 39.2705L16.7135 36.3365L19.1278 33.8109L26.4074 35.0735L22.9905 28.3881L26.4074 21.7028Z" fill="#FFD396"/>
                            <path d="M22.9904 28.3883L26.4074 35.0736L19.1279 33.8109L16.7136 36.3365V20.4771L19.1279 23.0027L26.4074 21.7028L22.9904 28.3883Z" fill="#FFB64C"/>
                            </svg>                                
                    </div>
                    <div class="header ml-2">
                        <div class="title">
                            Yarim <br> Bilet
                        </div>
                        <div class="info mt-1">
                            {{half_ticket_cost}} PUAN
                        </div>
                    </div>
                </div>
                <div class="ticket-content mt-5">
                    <div class="point">
                        {{half_ticket_shop_cost}} PUAN
                    </div>
                     <div class="count">
                        <button (click)="remove('half')" class="minus">-</button>
                        <span class="number">{{half_ticket_shop_count}}</span>
                        <button (click)="add('half')"  class="plus">+</button>
                     </div>
                </div>
                <button (click)="buy_ticket('half')" class="buy-button mt-5">
                    Bilet Satın Al
                </button>
            </div>
            <div id="ticket_3" class="ticket">
                <div class="ticket-header">
                    <div class="icon">
                        <svg width="54" height="64" viewBox="0 0 54 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.5179 0.440674L42.8905 2.19981C42.3973 3.59806 41.3816 4.717 40.0324 5.3536C38.6758 5.9902 37.1597 6.06808 35.7487 5.56221C34.715 5.20132 33.7648 4.47769 33.0755 3.52018L29.7133 6.2804V55.9595V59.6736L33.4274 63.3878L54.0002 4.23094L43.5179 0.440674Z" fill="#AE2538"/>
                            <path d="M33.4272 44.6934L31.1244 46.5504L33.4272 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596C13.6307 55.9596 11.1424 58.4479 11.1424 61.5308V63.3878H0V48.4075L3.71413 46.5504L0 44.6934V0H11.1424V1.85707C11.1424 4.93967 13.6307 7.42826 16.7136 7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4272V44.6934Z" fill="#FF6C6C"/>
                            <path d="M33.4271 44.6934L31.1244 46.5504L33.4271 48.4075V63.3878H22.2848V61.5308C22.2848 58.4479 19.7964 55.9596 16.7136 55.9596V7.42826C19.7964 7.42826 22.2848 4.93967 22.2848 1.85707V0H33.4271V44.6934Z" fill="#E63950"/>
                            <path d="M0 44.6934H7.42826V48.4075H0V44.6934Z" fill="#FFD396"/>
                            <path d="M11.1424 44.6934H14.8565V48.4075H11.1424V44.6934Z" fill="#FFD396"/>
                            <path d="M18.5705 44.6934H22.2847V48.4075H18.5705V44.6934Z" fill="#FFB64C"/>
                            <path d="M25.999 44.6934H33.4273V48.4075H25.999V44.6934Z" fill="#FFB64C"/>
                            <path d="M26.4074 21.7028L19.1278 23.0026L16.7135 20.477L13.9279 17.543L12.8879 25.0825L6.31396 28.3881L12.8879 31.7309L13.9279 39.2705L16.7135 36.3365L19.1278 33.8109L26.4074 35.0735L22.9905 28.3881L26.4074 21.7028Z" fill="#FFD396"/>
                            <path d="M22.9904 28.3883L26.4074 35.0736L19.1279 33.8109L16.7136 36.3365V20.4771L19.1279 23.0027L26.4074 21.7028L22.9904 28.3883Z" fill="#FFB64C"/>
                            </svg>                                
                    </div>
                    <div class="header ml-2">
                        <div class="title">
                            Tam <br> Bilet
                        </div>
                        <div class="info mt-1">
                            {{full_ticket_cost}} PUAN
                        </div>
                    </div>
                </div>
                <div class="ticket-content mt-5">
                    <div class="point">
                        {{full_ticket_shop_cost}} PUAN
                    </div>
                     <div class="count">
                         <button (click)="remove('full')" class="minus">-</button>
                         <span class="number">{{full_ticket_shop_count}}</span>
                         <button (click)="add('full')"  class="plus">+</button>
                     </div>
                </div>
                <button (click)="buy_ticket('full')" class="buy-button mt-5">
                    Bilet Satın Al
                </button>
            </div>
            <span (click)="next()" style="display: none;width: 35px;"class="right">
                <svg style="float: right;" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.269625 22.52L11.5496 11.576L0.269625 0.631999H6.70163L17.9336 11.576L6.70163 22.52H0.269625Z" fill="white"/>
                </svg>
            </span>

        </div>
    </div>

    <div *ngIf="!loading" class="container-footer mb-5">
        <div class="affiliate">
            <div class="header">
                <div class="title">
                    Kazanmaya hazır mısın?
                </div>
                <div class="info">
                    Sende hemen arkadaşını davet et ve <span>%{{profile.commission}}</span> affilate <br> ile ekstra puan kazan!
                </div>
            </div>
            <div class="content mt-5">
                <div *ngIf="user_id != 0" class="detail">
                    <input type="text" disabled class="adress" value="{{affiliateURL}}{{user_id}}" />
                    <button (click)="copy()" class="copy-url">Kopyala</button>
                </div>
                <div *ngIf="user_id == 0" style="width:100%"  class="detail">
                    <input style="font-size: 14px;
                    max-width: 100%;width: 100%;" type="text" disabled class="adress" value="Affiliate katılım hakkınız bulunmamaktadır." />
                </div>
            </div>
        </div>
        <div class="top-winner">
            <div class="header">Top 5 <br> Kazananlar</div>
            <div class="content mt-3">
                <div *ngFor="let data of profile.top_winner_data"class="item">
                    <div class="username">{{data.username}}</div>
                    <div *ngIf="data.ticket_type == 0" class="ticket-type">Çeyrek Bilet</div>
                    <div *ngIf="data.ticket_type == 1" class="ticket-type">Yarım Bilet</div>
                    <div *ngIf="data.ticket_type == 2" class="ticket-type">Tam Bilet</div>

                    <div class="amount">{{data.prize}}₺</div>
                </div>
                <!--<div class="item">
                    <div class="username">demir</div>
                    <div class="ticket-type">Tam Bilet</div>
                    <div class="amount">1.500₺</div>
                </div>
                <div class="item">
                    <div class="username">demir</div>
                    <div class="ticket-type">Tam Bilet</div>
                    <div class="amount">1.500₺</div>
                </div>
                <div class="item">
                    <div class="username">demir</div>
                    <div class="ticket-type">Tam Bilet</div>
                    <div class="amount">1.500₺</div>
                </div>
                <div class="item">
                    <div class="username">demir</div>
                    <div class="ticket-type">Tam Bilet</div>
                    <div class="amount">1.500₺</div>
                </div>-->
            </div>
        </div>
    </div>
</div>