import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public httpClient:HttpClient) { }
  login(username_data){
    const params = new FormData();
    params.append('username', username_data);
    params.append('operator_id', environment.operatorId);
    if(localStorage.getItem("reference") != null){
      var reference_data = localStorage.getItem("reference");
      var expired_time = JSON.parse(reference_data)["expired_time"];
      var now = new Date().getTime();
      var reference_id  = JSON.parse(reference_data)["id"];
      if(now <= expired_time){
        params.append('reference_id',reference_id);
        localStorage.removeItem("reference")
      }else{
        localStorage.removeItem("reference")
      }
    }
    return this.httpClient.post<any>(environment.apiUrl+'login', params)
  }

  check_user_session(){
    var token = localStorage.getItem("token")
    var user_data = localStorage.getItem("userdata");
    var username = JSON.parse(user_data)["username"]
    const params = new FormData();
    params.append('username', username);
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'check_user_session', params,options)
  }

  login_with_password(password){
    const params = new FormData();
    let headers = new HttpHeaders();
    var username = localStorage.getItem("username")
    var operator_id = environment.operatorId;
    params.append('username',username);
    params.append('password', password);
    params.append('operator_id',operator_id);
    return this.httpClient.post<any>(environment.apiUrl+'password-login', params)
  }

  set_password(email,password){
    var token = localStorage.getItem("token")
    const params = new FormData();
    params.append('email', email);
    params.append('password', password);
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'set-password', params,options)
  }

  change_password(current_password,password){
    var token = localStorage.getItem("token")
    const params = new FormData();
    params.append('password', current_password);
    params.append('new_password', password);
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'change-password', params,options)
  }

  skip_security(){
    var token = localStorage.getItem("token")
    const params = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'skip-security',params,options)
  }

  operator_detail(){
    var token = localStorage.getItem("token")
    const params = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'operator-detail',params,options)
  }

  get_statistic_data(){
    const params = new FormData();
    params.append("operator_id",environment.operatorId)
    return this.httpClient.post<any>(environment.apiUrl+'get-statistic-data',params)
  }


  user_detail(){
    var token = localStorage.getItem("token")
    const params = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'user-detail',params,options)
  }

  point_request(){
    var token = localStorage.getItem("token")
    var user_data = localStorage.getItem("userdata");
    var username = JSON.parse(user_data)["username"]
    const params = new FormData();
    params.append('username', username);
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'point-request', params,options)
  }

  buy_ticket(type){
      var token = localStorage.getItem("token")
      const params = new FormData();
      params.append('ticket_type', type);
      let headers = new HttpHeaders();
      headers = headers.append('token', token);
      let options = {headers:headers};
      return this.httpClient.post<any>(environment.apiUrl+'buy-ticket', params,options)
  }

  user_tickets(){
    var token = localStorage.getItem("token")
    const params = new FormData();
    let headers = new HttpHeaders();
    headers = headers.append('token', token);
    let options = {headers:headers};
    return this.httpClient.post<any>(environment.apiUrl+'user-tickets', params,options)
  }

  get_operator_config(){
    const params = new FormData();
    params.append('operator_id', environment.operatorId);
    return this.httpClient.post<any>(environment.apiUrl+'get-operator-config', params)
  }
}
