<div *ngIf="type==0" class="lottery-header show">
    <div id="reloadPage1" (click)="reload_page()" class="logo">
        <img class="mr-2" src="{{operator_image}}" /> <span>{{operator_name}} <span class="active">Piyango</span></span>
    </div>
    <div class="menu">
        <a target="_blank" href="{{go_website}}" class="btn">Siteye Git</a>
    </div>
</div>
<div *ngIf="type==1" >
    <div class="lottery-header">
        <div id="reloadPage2" (click)="reload_page()" class="logo">
            <img class="mr-2" src="{{operator_image}}" /> <span>{{operator_name}} <span class="active">Piyango</span></span>
        </div>
        <div class="menu">
            <a (click)="menu('profile')" class="item">Anasayfa</a>

            <a  *ngIf="user" (click)="menu('settings')" class="item">Ayarlar</a>

            <a (click)="logout()" class="item">Çıkış Yap</a>

            <a (click)="menu('tickets')" class="item btn">Biletlerim</a>
        </div>
    </div>

    <div id="lotteryHeaderMobile" class="lottery-header-mobile">
        <div (click)="open_menu()" class="menu-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 7H19" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round"/>
                <path d="M5 12H19" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round"/>
                <path d="M5 17H19" stroke="#CCD2E3" stroke-width="2" stroke-linecap="round"/>
            </svg>                
        </div>
        <div id="reloadPage3" (click)="reload_page()" class="logo">
            <span>{{operator_name}} <span class="active">Piyango</span></span>
        </div>
        <div class="icon">
            <img src="{{operator_image}}" />              
        </div>
    </div>

    <div id="menuMobile" class="menu-mobile">
        <div class="close-button">
            <svg (click)="close_menu()" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7M13 13L7 7M7 7L1 13L13 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="items">
            <div (click)="menu('profile')" class="item mt-5">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 1.74963L0.833374 9.99996H3.33337V17.5H9.16671V12.5H10.8334V17.5H16.6667V9.99996H19.1667L10 1.74963ZM10 3.99247L15 8.4928V9.16663V15.8333H12.5V10.8333H7.50004V15.8333H5.00004V8.4928L10 3.99247Z" fill="white"/>
                    </svg>
                    
                    Anasayfa                    
            </div>

            <div (click)="menu('tickets')"  class="item mt-4">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2816 1.30142H4.7374C3.00406 1.29475 1.58323 2.67642 1.5424 4.40892V13.3364C1.50406 15.0972 2.8999 16.5564 4.66073 16.5956C4.68656 16.5956 4.71156 16.5964 4.7374 16.5956H11.3949C13.1399 16.5247 14.5149 15.0831 14.5024 13.3364V5.69808L10.2816 1.30142Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.0626 1.29163V3.71579C10.0626 4.89913 11.0193 5.85829 12.2026 5.86163H14.4985" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.90686 11.7987H5.40686" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.2028 8.67167H5.40613" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Biletlerim                    
            </div>
            <div *ngIf="user" (click)="menu('settings')" class="item mt-4">
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2816 1.30142H4.7374C3.00406 1.29475 1.58323 2.67642 1.5424 4.40892V13.3364C1.50406 15.0972 2.8999 16.5564 4.66073 16.5956C4.68656 16.5956 4.71156 16.5964 4.7374 16.5956H11.3949C13.1399 16.5247 14.5149 15.0831 14.5024 13.3364V5.69808L10.2816 1.30142Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.0626 1.29163V3.71579C10.0626 4.89913 11.0193 5.85829 12.2026 5.86163H14.4985" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.90686 11.7987H5.40686" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.2028 8.67167H5.40613" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Ayarlar                    
            </div>

            <div class="logout mt-5">
                <a (click)="logout()" class="btn">Çıkış Yap</a>
            </div>
        </div>
    </div>
</div>

