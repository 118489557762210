<div class="tickets-content container">
    <div class="tickets mr-2">
        <div class="header">
            <div class="title mr-5">
                <h2>Tüm Biletlerim</h2>
                <label>Tüm biletlerinizi buradan görüntüleyebilirsiniz.</label>
            </div>
            <div class="logo">
                <img src="../../../assets/img/all-tickets-header-img.png" />
            </div>
        </div>
        <mat-spinner  class="container mb-5" *ngIf="loading" diameter="75"></mat-spinner>
        <div *ngIf="!loading" class="content">
            <div class="content-header">
                <div class="date">
                    Tarih
                </div>
                <div class="tour-no">
                    Çekiliş No
                </div>
                <div class="ticket-no">
                    Bilet Numarası
                </div>
                <div class="ticket-type">
                    Bilet Türü
                </div>
            </div>
            <div class="content">
                <ng-container *ngFor="let data of all_tickets_data; let i = index" >
                    <div *ngIf="i%2 == 0" class="item active mt-2">
                        <div class="date">
                            {{data.created_at | date:'dd/MM/yy'}}
                        </div>
                        <div class="tour-no">
                            {{data.tour_no}}
                        </div>
                        <div class="ticket-no">
                            {{data.first_number}}-{{data.second_number}}-{{data.third_number}}-{{data.fourth_number}}<span *ngIf="data.fifth_number != null">-{{data.fifth_number}}</span>
                        </div>
                        <div class="ticket-type">
                            <span *ngIf="data.ticket_type == 0">Çeyrek</span>
                            <span *ngIf="data.ticket_type == 1">Yarım</span>
                            <span *ngIf="data.ticket_type == 2">Tam</span>

                        </div>
                    </div>
                    <div *ngIf="i%2 != 0" class="item mt-2">
                        <div class="date">
                            {{data.created_at | date:'dd/MM/yy'}}
                        </div>
                        <div class="tour-no">
                            {{data.tour_no}}
                        </div>
                        <div class="ticket-no">
                            {{data.first_number}}-{{data.second_number}}-{{data.third_number}}-{{data.fourth_number}}<span *ngIf="data.fifth_number != null">-{{data.fifth_number}}</span>
                        </div>
                        <div class="ticket-type">
                            <span *ngIf="data.ticket_type == 0">Çeyrek</span>
                            <span *ngIf="data.ticket_type == 1">Yarım</span>
                            <span *ngIf="data.ticket_type == 2">Tam</span>

                        </div>
                    </div>
                    
                </ng-container>
            </div>
        </div>
    </div>
    <div class="tickets ml-2">
        <div class="header">
            <div class="title">
                <h2>Kazanan Biletlerim</h2>
                <label>Kazandığınız tüm biletleri buradan görüntüleyebilirsiniz.</label>
            </div>
            <div class="logo">
                <img src="../../../assets/img/win-tickets-header-img.png" />
            </div>
        </div>
        <mat-spinner  class="container mb-5" *ngIf="loading" diameter="75"></mat-spinner>
        <div *ngIf="!loading" class="content">
            <div class="content-header">
                <div class="tour-no">
                    Çekiliş No
                </div>
                <div class="ticket-no">
                    Bilet Numarası
                </div>
                <div class="ticket-type">
                    Bilet Türü
                </div>
                <div class="award">
                    Ödül
                </div>
            </div>
            <div class="content">
                <ng-container *ngFor="let data of win_tickets_data; let k = index" >
                    <div *ngIf="k%2==0" class="item active mt-2">
                        <div class="tour-no">
                            {{data.tour_no}}
                        </div>
                        <div class="ticket-no">
                            {{data.first_number}}-{{data.second_number}}-{{data.third_number}}-{{data.fourth_number}}<span *ngIf="data.fifth_number != null">-{{data.fifth_number}}</span>
                        </div>
                        <div class="ticket-type">
                            <span *ngIf="data.ticket_type == 0">Çeyrek</span>
                            <span *ngIf="data.ticket_type == 1">Yarım</span>
                            <span *ngIf="data.ticket_type == 2">Tam</span>
                        </div>
                        <div class="award">
                            <span>{{data.prize}}₺</span>
                        </div>
                    </div>
                    <div *ngIf="k%2!=0" class="item mt-2">
                        <div class="tour-no">
                            {{data.tour_no}}
                        </div>
                        <div class="ticket-no">
                            {{data.first_number}}-{{data.second_number}}-{{data.third_number}}-{{data.fourth_number}}<span *ngIf="data.fifth_number != null">-{{data.fifth_number}}</span>
                        </div>
                        <div class="ticket-type">
                            <span *ngIf="data.ticket_type == 0">Çeyrek</span>
                            <span *ngIf="data.ticket_type == 1">Yarım</span>
                            <span *ngIf="data.ticket_type == 2">Tam</span>
                        </div>
                        <div class="award">
                            <span>{{data.prize}}₺</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>