import { Component, OnInit,Input } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ProfileContentComponent } from 'src/app/pages/profile-content/profile-content.component';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { ApiService } from 'src/app/services/api.service';
import { BalanceService } from 'src/app/services/balance.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.css']
})
export class HeaderPanelComponent implements OnInit {
  @Input() data;
  constructor(private notification:NotificationsService,private api:ApiService,private profile:ProfileComponent,public balanceService:BalanceService) {
    
   }
  operatorName = environment.operator_name
  prize
  tour_no
  countdown_seconds
  countdown_minutes
  countdown_days
  countdown_hours
  lottery_date_time
  lottery_date_month
  lottery_date_day
  loading = true;
  countdown_check = 0;
  web;
  full_ticket_cost
  half_ticket_cost
  quarter_ticket_cost
  ngOnInit(): void {
    if(window.screen.width > 920){
      this.web = true;
    }
    var a = setInterval(()=>{
      if(this.profile.user_detail_loading == true && this.profile.operator_detail_loading == true){
        this.prize = this.profile.prize;
        this.tour_no = this.profile.tour_no;
        this.countdown_seconds = this.profile.countdown_seconds;
        this.countdown_minutes = this.profile.countdown_minutes;
        this.countdown_hours  = this.profile.countdown_hours
        this.countdown_days = this.profile.countdown_days;
        this.lottery_date_day = this.profile.lottery_date_day;
        this.lottery_date_month = this.profile.lottery_date_month;
        this.lottery_date_time = this.profile.lottery_date_time;
        this.countdown_check = this.profile.countdown_check;
        this.full_ticket_cost = this.profile.full_ticket_cost
        this.half_ticket_cost = this.profile.half_ticket_cost;
        this.quarter_ticket_cost = this.profile.quarter_ticket_cost;
        this.loading = false;
        clearInterval(a);
      }
    },1000)
    setInterval(()=>{
      if(this.countdown_check == 1){
        if(this.countdown_seconds-1 >= 0){
          this.countdown_seconds = this.countdown_seconds -1;
        }else{
          if(this.countdown_minutes-1 >= 0 ){
            this.countdown_seconds = 59;
            this.countdown_minutes = this.countdown_minutes-1;
          }else{
            if(this.countdown_hours-1 >= 0){
              this.countdown_minutes = 59;
              this.countdown_hours = this.countdown_hours-1;
            }else{
              if(this.countdown_days-1 >= 0){
                this.countdown_hours = 23;
                this.countdown_days = this.countdown_days-1;
              }
            }
          }
        }
      }
    },1000)
  }

  point_request(){
    document.getElementsByClassName("point-request-button")[0].setAttribute("disabled","disabled");
    document.getElementsByClassName("point-request-button")[0].classList.add("btn-disabled")
    this.api.point_request().subscribe(data=>{
      if(data.error == false){
        this.show_notification(1,data.msg)
        document.getElementsByClassName("point-request-button")[0].removeAttribute("disabled");
        document.getElementsByClassName("point-request-button")[0].classList.remove("btn-disabled")

      }else{
        document.getElementsByClassName("point-request-button")[0].removeAttribute("disabled");
        document.getElementsByClassName("point-request-button")[0].classList.remove("btn-disabled")

        this.show_notification(2,data.msg)
      }
    })
  }

  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }


}
