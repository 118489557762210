import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {

  constructor(private notification:NotificationsService,private api:ApiService) { }
  all_tickets_data;
  win_tickets_data;
  loading = true;
  ngOnInit(): void {
    
    this.api.user_tickets().subscribe(data=>{
      this.loading = false;
      if(data.error == false){
        this.all_tickets_data=data.all_tickets;
        this.win_tickets_data = data.win_tickets;
      }else{
        this.show_notification(2,data.msg)
      }
    })
  }

  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

}
