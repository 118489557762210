import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'piyango-clientside';
  authentication = false;
  check_session = false;
  constructor(private router:Router, public api:ApiService, public activatedRoute:ActivatedRoute){
   // if(this.activatedRoute.component)
  }

  ngOnInit() {
    this.check_user_session()
  }

  check_user_session(){
    if(localStorage.getItem("token") != null && localStorage.getItem("userdata") != null){
        this.api.check_user_session().subscribe(data=>{
          this.check_session =true;
          if(data.success == true){
            this.authentication =true;
            if(this.router.url=="/"){
              this.router.navigate(['/profile']);
            }else{
              if(window.location.hash.indexOf("#/reference/") != -1 && window.location.pathname == "/"){
                this.router.navigate(['/profile'])
              }
            }
          }else{
            if(localStorage.getItem("reference") != null){
              var reference_data = localStorage.getItem("reference");
              localStorage.clear();
              localStorage.setItem("reference",reference_data);
            }else{
              localStorage.clear();
            }
            if(window.location.hash.indexOf("#/reference/") != -1 && window.location.pathname == "/"){
              var affiliate_data = window.location.hash.split("#/reference/")
              var now = new Date();
              var expired_date = new Date(now.getTime() + (20 * 60 * 1000)).getTime();
              var reference = '{"id":'+affiliate_data[1]+',"expired_time":'+expired_date+'}';
              localStorage.setItem("reference",reference);
            }
            this.router.navigate(['/']);
          }
        },err => {
          if(localStorage.getItem("reference") != null){
            var reference_data = localStorage.getItem("reference");
            localStorage.clear();
            localStorage.setItem("reference",reference_data);
          }else{
            localStorage.clear();
          }
          localStorage.clear();
          if(window.location.hash.indexOf("#/reference/") != -1 && window.location.pathname == "/"){
            var affiliate_data = window.location.hash.split("#/reference/")
            var now = new Date();
            var expired_date = new Date(now.getTime() + (20 * 60 * 1000)).getTime();
            var reference = '{"id":'+affiliate_data[1]+',"expired_time":'+expired_date+'}';
            localStorage.setItem("reference",reference);
          }
          window.location.reload();
        });
   }else{
    if(localStorage.getItem("reference") != null){
      var reference_data = localStorage.getItem("reference");
      localStorage.clear();
      localStorage.setItem("reference",reference_data);
    }else{
      localStorage.clear();
    }
    if(window.location.hash.indexOf("#/reference/") != -1 && window.location.pathname == "/"){
      var affiliate_data = window.location.hash.split("#/reference/")
      var now = new Date();
      var expired_date = new Date(now.getTime() + (20 * 60 * 1000)).getTime();
      var reference = '{"id":'+affiliate_data[1]+',"expired_time":'+expired_date+'}';
      localStorage.setItem("reference",reference);
      
    }
    this.router.navigate(["/"])
    this.check_session = true;
  }
  }
}


