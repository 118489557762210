<div class="main">
    <app-header></app-header>
    <div class="container mt-5 mb-5">
        <div class="container-header mb-3">
            <h2>Şifreni Gir</h2>
            <p>Hesabını güvene aldığını fark ettik, lütfen devam etmek için şifreni gir.</p>
        </div>
        <div class="container-content">
            <div class="item show-password mb-5">
                <input [(ngModel)]="password"  type="password" placeholder="Şifre" />
                <span>
                    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 24.5121C13.8369 24.5304 11.2111 23.8861 8.85901 22.6371C6.91998 21.5832 5.18884 20.1855 3.75 18.5121C2.18512 16.727 0.918823 14.7009 0 12.5121C0.920959 10.3244 2.18707 8.2986 3.75 6.5121C5.18884 4.8387 6.91998 3.44099 8.85901 2.3871C11.2111 1.13814 13.8369 0.493788 16.5 0.512098C19.1631 0.493788 21.7889 1.13814 24.141 2.3871C26.08 3.44099 27.8112 4.8387 29.25 6.5121C30.8149 8.29719 32.0812 10.3233 33 12.5121C32.079 14.6998 30.8129 16.7256 29.25 18.5121C27.8112 20.1855 26.08 21.5832 24.141 22.6371C21.7889 23.8861 19.1631 24.5304 16.5 24.5121ZM16.5 3.5121C8.67297 3.5121 4.65302 9.81209 3.28699 12.5171C4.638 15.2211 8.63098 21.5121 16.5 21.5121C24.326 21.5121 28.348 15.2121 29.714 12.5061C28.364 9.80311 24.373 3.5121 16.5 3.5121ZM16.5 18.5121C13.1863 18.5121 10.5 15.8258 10.5 12.5121C10.5 9.19838 13.1863 6.5121 16.5 6.5121C19.8137 6.5121 22.5 9.19838 22.5 12.5121C22.4968 15.8243 19.8125 18.5087 16.5001 18.5121H16.5ZM16.5 9.5121C14.8431 9.5121 13.5 10.8552 13.5 12.5121C13.5 14.169 14.8431 15.5121 16.5 15.5121C18.1569 15.5121 19.5 14.169 19.5 12.5121C19.5 10.8552 18.1569 9.5121 16.5 9.5121Z" fill="white"/>
                        </g>
                    </svg>                        
                </span>
            </div>
            <div class="container-buttons">
                <input (click)="go_back()" class="skip-button" type="submit"   value="Geri Dön" />
                <input (click)="login()" class="secure-button" type="submit" value="Giriş Yap" />
            </div>
        </div>
        <div class="container-footer p-5">
            <p class="forgot-password">Şifremi unuttum</p>
        </div>
    </div>
    <app-footer></app-footer>

</div>