import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { environment } from 'src/environments/environment';
import { ProfileComponent } from '../profile/profile.component';
import { ApiService } from 'src/app/services/api.service';
import { HeaderPanelComponent } from 'src/app/components/header-panel/header-panel.component';
import { BalanceService } from 'src/app/services/balance.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.css']
})
export class ProfileContentComponent implements OnInit {

  constructor(private clipboardApi: ClipboardService,public profile:ProfileComponent, private notification:NotificationsService,private api:ApiService,public balanceService:BalanceService,public headerPanel:HeaderPanelComponent) { 
  }
  sliderTicket =0;
  affiliateURL = environment.operatorAffiliateUrl;
  operatorName = environment.operator_name
  user_id 
  full_ticket_cost
  half_ticket_cost
  quarter_ticket_cost
  prize
  commission
  tour_no
  username
  loading = true;
  user_detail_loading = false;
  full_ticket_shop_count = 1;
  half_ticket_shop_count = 1;
  quarter_ticket_shop_count =1;
  full_ticket_shop_cost
  half_ticket_shop_cost
  quarter_ticket_shop_cost
  top_winner_data;
  lottery_date_month
  lottery_date_day
  lottery_date_time

  countdown_check = 0;
  countdown_days = 0;
  countdown_minutes = 0;
  countdown_hours = 0;
  countdown_seconds = 0;
  ngOnInit(): void {
    
    var a = setInterval(()=>{
      if(this.profile.user_detail_loading == true && this.profile.operator_detail_loading == true){
        this.loading = false;
        this.prize = this.profile.prize;
        this.tour_no = this.profile.tour_no;
        this.countdown_seconds = this.profile.countdown_seconds;
        this.countdown_minutes = this.profile.countdown_minutes;
        this.countdown_hours  = this.profile.countdown_hours
        this.countdown_days = this.profile.countdown_days;
        this.lottery_date_day = this.profile.lottery_date_day;
        this.lottery_date_month = this.profile.lottery_date_month;
        this.lottery_date_time = this.profile.lottery_date_time;
        this.countdown_check = this.profile.countdown_check;
        this.full_ticket_cost = this.profile.full_ticket_cost
        this.half_ticket_cost = this.profile.half_ticket_cost;
        this.quarter_ticket_cost = this.profile.quarter_ticket_cost;
        this.full_ticket_shop_cost = this.profile.full_ticket_shop_cost;
        this.half_ticket_shop_cost = this.profile.half_ticket_shop_cost;
        this.quarter_ticket_shop_cost = this.profile.quarter_ticket_shop_cost;
        this.user_id = this.profile.user_id;
        var user_data = localStorage.getItem("user")
        if(user_data == 'false'){
          this.user_id = 0;
        }
        
        clearInterval(a);
      }
    })
  }


  buy_ticket(type){
    var answer = 0;
    if(type=="full"){
      if(this.balanceService.balance - this.full_ticket_shop_cost >= 0){
        document.getElementsByClassName("buy-button")[2].setAttribute("disabled","disabled");
        document.getElementsByClassName("buy-button")[2].classList.add("btn-disabled")
        var count = this.full_ticket_shop_count;
        var second = 1;
        var start = 0;
          this.api.buy_ticket(type).subscribe(data=>{
            count = count-1;
            start = 1;
            if(data.error == false){
              this.balanceService.balance = this.balanceService.balance - this.full_ticket_cost;
              this.show_notification(1,data.msg)
            }else{
              this.show_notification(2,data.msg)
            }
          })
          var a = setInterval(()=>{
            if(start == 1){
              if(answer == 0){
                if(count > 0){
                  answer = 1;
                  if(second > 0){
                    answer = 0;
                    second = second -1;
                  }else if(second == 0){
                    second =1;
                    this.api.buy_ticket(type).subscribe(data=>{
                      count = count -1;
                      answer =0;
                      if(data.error == false){
                        this.balanceService.balance = this.balanceService.balance - this.full_ticket_cost;
                        this.show_ticket_notification(1,data.msg)
                      }else{
                        this.show_ticket_notification(2,data.msg)
                      }
                    })
                  }
                }else if(count == 0){
                  setTimeout(() => {
                    document.getElementsByClassName("buy-button")[2].removeAttribute("disabled");
                    document.getElementsByClassName("buy-button")[2].classList.remove("btn-disabled")
                    clearInterval(a);
                  }, 1000);
                }
              }
            } 
          },1000)
          
      }else{
        this.show_notification(2,"İşlemi gerçekleştirmek için yeterli bakiyeniz bulunmamaktadır.")
      }
     
    }else if(type=="half"){
      if(this.balanceService.balance - this.half_ticket_shop_cost >= 0){
        document.getElementsByClassName("buy-button")[1].setAttribute("disabled","disabled");
        document.getElementsByClassName("buy-button")[1].classList.add("btn-disabled")
        var count = this.half_ticket_shop_count;
        var second = 1;
        var start = 0;
          this.api.buy_ticket(type).subscribe(data=>{
            count = count-1;
            start = 1;
            if(data.error == false){
              this.balanceService.balance = this.balanceService.balance - this.half_ticket_cost;
              this.show_ticket_notification(1,data.msg)
            }else{
              this.show_ticket_notification(2,data.msg)
            }
          })
          var a = setInterval(()=>{
            if(start == 1){
              if(answer == 0){
                if(count > 0){
                  answer = 1;
                  if(second > 0){
                    answer = 0;
                    second = second -1;
                  }else if(second == 0){
                    second =1;
                    this.api.buy_ticket(type).subscribe(data=>{
                      count = count -1;
                      answer =0;
                      if(data.error == false){
                        this.balanceService.balance = this.balanceService.balance - this.half_ticket_cost;
                        this.show_ticket_notification(1,data.msg)
                      }else{
                        this.show_ticket_notification(2,data.msg)
                      }
                    })
                  }
                }else if(count == 0){
                  setTimeout(() => {
                    document.getElementsByClassName("buy-button")[1].removeAttribute("disabled");
                    document.getElementsByClassName("buy-button")[1].classList.remove("btn-disabled")
                    clearInterval(a);
                  }, 1000);
                }
              }
            } 
          },1000)
      }else{
        this.show_notification(2,"İşlemi gerçekleştirmek için yeterli bakiyeniz bulunmamaktadır.")
      }
      
    }else if(type=="quarter"){
      if(this.balanceService.balance - this.quarter_ticket_shop_cost >= 0){
        document.getElementsByClassName("buy-button")[0].setAttribute("disabled","disabled");
        document.getElementsByClassName("buy-button")[0].classList.add("btn-disabled")
        var count = this.quarter_ticket_shop_count;
        var second = 1;
        var start = 0;
        this.api.buy_ticket(type).subscribe(data=>{
          count = count-1;
          start = 1;
          if(data.error == false){
            this.balanceService.balance = this.balanceService.balance - this.quarter_ticket_cost;
            this.show_ticket_notification(1,data.msg)
          }else{
            this.show_ticket_notification(2,data.msg)
          }
        })
        var a = setInterval(()=>{
          if(start == 1){
            if(answer == 0){
              if(count > 0){
                answer = 1;
                if(second > 0){
                  answer = 0;
                  second = second -1;
                }else if(second == 0){
                  second =1;
                  this.api.buy_ticket(type).subscribe(data=>{
                    count = count -1;
                    answer =0;
                    if(data.error == false){
                      this.balanceService.balance = this.balanceService.balance - this.quarter_ticket_cost;
                      this.show_ticket_notification(1,data.msg)
                    }else{
                      this.show_ticket_notification(2,data.msg)
                    }
                  })
                }
              }else if(count == 0){
                setTimeout(() => {
                  document.getElementsByClassName("buy-button")[0].removeAttribute("disabled");
                  document.getElementsByClassName("buy-button")[0].classList.remove("btn-disabled")
                  clearInterval(a);
                }, 1000);
                
              }
            }
          } 
        },1000)
      }else{
        this.show_notification(2,"İşlemi gerçekleştirmek için yeterli bakiyeniz bulunmamaktadır.")
      }
    }else{
      this.show_notification(2,"Hatalı Bilet Seçimi.")
    }
  }

  add(type){
    if(type=="full"){
      if(this.full_ticket_shop_count+1 != 11){
        this.full_ticket_shop_count = this.full_ticket_shop_count+1;
        this.full_ticket_shop_cost = this.full_ticket_cost*this.full_ticket_shop_count
      }
    }else if(type == "half"){
      if(this.half_ticket_shop_count+1 != 11){
        this.half_ticket_shop_count = this.half_ticket_shop_count+1;
        this.half_ticket_shop_cost = this.half_ticket_cost*this.half_ticket_shop_count
      }
    }else if(type=="quarter"){
      if(this.quarter_ticket_shop_count+1 != 11){
        this.quarter_ticket_shop_count = this.quarter_ticket_shop_count+1;
        this.quarter_ticket_shop_cost = this.quarter_ticket_cost*this.quarter_ticket_shop_count
      }
    }
  }

  remove(type){
    if(type=="full"){
      if(this.full_ticket_shop_count-1 != 0){
        this.full_ticket_shop_count = this.full_ticket_shop_count-1;
        this.full_ticket_shop_cost = this.full_ticket_cost*this.full_ticket_shop_count
      }


    }else if(type == "half"){
      if(this.half_ticket_shop_count-1 != 0){
        this.half_ticket_shop_count = this.half_ticket_shop_count-1;
        this.half_ticket_shop_cost = this.half_ticket_cost*this.half_ticket_shop_count
      }
 

    }else if(type=="quarter"){
      if(this.quarter_ticket_shop_count-1 != 0){
        this.quarter_ticket_shop_count = this.quarter_ticket_shop_count-1;
        this.quarter_ticket_shop_cost = this.quarter_ticket_cost*this.quarter_ticket_shop_count
      }


    }
  }


  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

  show_ticket_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:3000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:3000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

  previous(){
    if(this.sliderTicket == 0){
      this.sliderTicket = 2;
      document.getElementById("ticket_1").classList.add("animated")
      document.getElementById("ticket_1").classList.add("fadeOutRight")
      setTimeout(() => {
        document.getElementById("ticket_1").style.display="none"
        document.getElementById("ticket_3").style.display="block"
        document.getElementById("ticket_1").classList.remove("animated")
        document.getElementById("ticket_1").classList.remove("fadeOutRight")

      }, 300);
      document.getElementById("ticket_3").classList.add("animated")
      document.getElementById("ticket_3").classList.add("fadeInLeft")
      setTimeout(()=>{
        document.getElementById("ticket_3").classList.remove("animated")
        document.getElementById("ticket_3").classList.remove("fadeInLeft")

      },300)

    }else if(this.sliderTicket == 1){
      this.sliderTicket = 0;
      document.getElementById("ticket_2").classList.add("animated")
      document.getElementById("ticket_2").classList.add("fadeOutRight")
      setTimeout(() => {
        document.getElementById("ticket_2").style.display="none"
        document.getElementById("ticket_1").style.display="block"
        document.getElementById("ticket_2").classList.remove("animated")
        document.getElementById("ticket_2").classList.remove("fadeOutRight")

      }, 300);
      document.getElementById("ticket_1").classList.add("animated")
      document.getElementById("ticket_1").classList.add("fadeInLeft")
      setTimeout(()=>{
        document.getElementById("ticket_1").classList.remove("animated")
        document.getElementById("ticket_1").classList.remove("fadeInLeft")

      },300)

    }else if(this.sliderTicket == 2){
      this.sliderTicket = 1;
      document.getElementById("ticket_3").classList.add("animated")
      document.getElementById("ticket_3").classList.add("fadeOutRight")
      setTimeout(() => {
        document.getElementById("ticket_3").style.display="none"
        document.getElementById("ticket_2").style.display="block"
        document.getElementById("ticket_3").classList.remove("animated")
        document.getElementById("ticket_3").classList.remove("fadeOutRight")

      }, 300);
      document.getElementById("ticket_2").classList.add("animated")
      document.getElementById("ticket_2").classList.add("fadeInLeft")
      setTimeout(()=>{
        document.getElementById("ticket_2").classList.remove("animated")
        document.getElementById("ticket_2").classList.remove("fadeInLeft")

      },300)
    }
  }

  next(){
    if(this.sliderTicket == 0){
      this.sliderTicket = 1;
      document.getElementById("ticket_1").classList.add("animated")
      document.getElementById("ticket_1").classList.add("fadeOutLeft")
      setTimeout(() => {
        document.getElementById("ticket_1").style.display="none"
        document.getElementById("ticket_2").style.display="block"
        document.getElementById("ticket_1").classList.remove("animated")
        document.getElementById("ticket_1").classList.remove("fadeOutLeft")

      }, 300);
      document.getElementById("ticket_2").classList.add("animated")
      document.getElementById("ticket_2").classList.add("fadeInRight")
      setTimeout(()=>{
        document.getElementById("ticket_2").classList.remove("animated")
        document.getElementById("ticket_2").classList.remove("fadeInRight")

      },300)

    }else if(this.sliderTicket == 1){
      this.sliderTicket = 2;
      document.getElementById("ticket_2").classList.add("animated")
      document.getElementById("ticket_2").classList.add("fadeOutLeft")
      setTimeout(() => {
        document.getElementById("ticket_2").style.display="none"
        document.getElementById("ticket_3").style.display="block"
        document.getElementById("ticket_2").classList.remove("animated")
        document.getElementById("ticket_2").classList.remove("fadeOutLeft")

      }, 300);
      document.getElementById("ticket_3").classList.add("animated")
      document.getElementById("ticket_3").classList.add("fadeInRight")
      setTimeout(()=>{
        document.getElementById("ticket_3").classList.remove("animated")
        document.getElementById("ticket_3").classList.remove("fadeInRight")

      },300)

    }else if(this.sliderTicket == 2){
      this.sliderTicket = 0;
      document.getElementById("ticket_3").classList.add("animated")
      document.getElementById("ticket_3").classList.add("fadeOutLeft")
      setTimeout(() => {
        document.getElementById("ticket_3").style.display="none"
        document.getElementById("ticket_1").style.display="block"
        document.getElementById("ticket_3").classList.remove("animated")
        document.getElementById("ticket_3").classList.remove("fadeOutLeft")

      }, 300);
      document.getElementById("ticket_1").classList.add("animated")
      document.getElementById("ticket_1").classList.add("fadeInRight")
      setTimeout(()=>{
        document.getElementById("ticket_1").classList.remove("animated")
        document.getElementById("ticket_1").classList.remove("fadeInRight")

      },300)
    }
  }

  copy(){
    var content = "https://"+this.affiliateURL + this.user_id;
    this.clipboardApi.copyFromContent(content);
    this.show_notification(1,"Affiliate linki kopyalandı.")
  }

}
