import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-safe',
  templateUrl: './account-safe.component.html',
  styleUrls: ['./account-safe.component.css']
})
export class AccountSafeComponent implements OnInit {

  constructor(public notification:NotificationsService,private api:ApiService,private router:Router) { }
  email
  password
  password_again
  show_password_val =0;
  ngOnInit(): void {
    if(localStorage.getItem("user") == "false"){
      var user_data = JSON.parse(localStorage.getItem('userdata'))
      if(user_data["mail"] ==null && user_data["id"] != null){
      }else{
        this.router.navigate(["profile"])
      }
    }
  }

  show_password(){
    if(this.show_password_val == 0){
      document.getElementById("password_input").setAttribute("type","text");
      document.getElementById("password_again_input").setAttribute("type","text");
      this.show_password_val = 1;

    }else{
      document.getElementById("password_input").setAttribute("type","password")
      document.getElementById("password_again_input").setAttribute("type","password");
      this.show_password_val = 0;

    }

  }

  save(){
    if(this.password == this.password_again){
      this.api.set_password(this.email,this.password).subscribe(data=>{
        if(data.error == false){
          this.show_notification(1,data.msg);
          this.router.navigate(["/profile"])
        }else{
          this.show_notification(2,data.msg);
        }
      })
    }else{
      this.show_notification(2,"Şifreler birbiriyle uyuşmuyor.")
    }
   
  }

  skip_security(){
    document.getElementsByClassName("skip-button")[0].setAttribute("disabled","disabled");
    this.api.skip_security().subscribe(data=>{
      if(data.success==true){
        this.router.navigate(["/profile"])
      }else{
        document.getElementsByClassName("skip-button")[0].removeAttribute("disabled");
      }
    })
  }

  show_notification(type,message){
    if(type == 1){
      this.notification.success("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true,
        theClass: "custom-notification"
      })
    }else if(type == 2)[
      this.notification.error("",message,{
        timeOut:7000,
        animate:"fromRight",
        showProgressBar: true
      })
    ]
  }

}
